import { takeLatest } from "redux-saga/effects";
import {
  GetEmailRes,
  verifyUser,
  getUserOtp,
} from "../../StateManagement/Reducers/SignupState";

import {
  LoginEmailCheckApi,
  VerifyUserWorker,
  getOtpWorker,
} from "./SignupSagaWorkers";

export function* LoginEmailCheckSaga() {
  yield takeLatest(GetEmailRes.type, LoginEmailCheckApi);
}

export function* VerifyUserSaga() {
  yield takeLatest(verifyUser.type, VerifyUserWorker);
}

export function* GetOtpSaga() {
  yield takeLatest(getUserOtp.type, getOtpWorker);
}
