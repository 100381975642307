import { createSlice } from "@reduxjs/toolkit";
import { Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";

export const SignupSlice = createSlice({
  name: "Signup",
  initialState: {
    EmailResp: {},
    otp: {},
    checkData: {
      clinic: "",
      username: "",
      mobile: "",
    },
    verified: false,
    saveLoading: false,
    showSuccess: false,
    verifiedEmail: "",
    facilityName: "",
  },
  reducers: {
    PostUserData: (state) => {
      return state;
    },
    userSignupEnd: (state, action) => {
      if (action.payload !== "failed") {
        state.showSuccess = true;
      }
    },
    GetEmailRes: (state) => {
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    verifyUser: (state, action) => {
      state.verified = true;
      state.facilityName = action.payload.facility;
    },
    endVeriyUser: (state, action) => {
      if (action.payload !== "failed") {
        state.verifiedEmail = action.payload;
      }
    },
    changeVerification: (state, action) => {
      state.verified = action.payload;
      if (!action.payload) {
        state.facilityName = "";
      }
    },
    checkSignUpData: (state) => {
      return state;
    },
    setCheckSignupData: (state, action) => {
      state.checkData = action.payload;
    },
    setSaveLoading: (state, action) => {
      state.saveLoading = action.payload;
    },
    getUserOtp: (state) => {
      return state;
    },
    setUserOtp: (state, action) => {
      if (action.payload.otp) {
        state.otp = action.payload.otp;
        localStorage.setItem(
          "LoginOTP",
          Encrypt_Value(action.payload.otp, "vozorpm")
        );
      }
    },
  },
});

export const {
  PostUserData,
  userSignupEnd,
  GetEmailRes,
  setEmailRes,
  setOtp,
  checkSignUpData,
  setCheckSignupData,
  setSaveLoading,
  verifyUser,
  endVeriyUser,
  getUserOtp,
  setUserOtp,
  changeVerification,
} = SignupSlice.actions;

export default SignupSlice.reducer;
