import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import EnterEmail from "../Modules/EnterEmail";
import SignUp from "../Modules/SignUp";
import VerifyAccount from "../Modules/VerifyAccount";

const Router = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<EnterEmail />} />
        <Route exact path="/enter_email" element={<EnterEmail />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/verify_account" element={<VerifyAccount />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default Router;
