import styled from "styled-components";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";

export const BsCheck = styled(BsCheckCircle)`
  font-size: 25px;
  color: #155724;
`;

export const IoClose = styled(IoCloseSharp)`
  font-size: 25px;
  color: #b00020;
`;
export const RiError = styled(RiErrorWarningFill)`
  font-size: 25px;
  color: #b00020;
`;
export const Modal = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  left: 0;
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1255)};
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;

export const ModalContainer = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  z-index: 1055;
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : " #ffffff"};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  cursor: auto;
  border: ${(p) => p.border && p.border};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  padding: ${(p) => p.padding && p.padding};
`;
