import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSaveLoading,
  userSignupEnd,
  verifyUser,
} from "../../StateManagement/Reducers/SignupState";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Success from "./success";
import axios from "axios";

import {
  Div,
  Input,
  PageTitle,
  FormLabel,
  FormGroup,
  SpanTag,
  Span,
  ErrorMessage,
  Image,
} from "../StyledComponents";

import {
  RowDiv,
  SignupButton,
  CheckBoxWrapper,
  CheckBoxLabel,
  TogggleCheck,
} from "../EnterEmail/styles";
import { AutoComplete } from "antd";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import { GoMail } from "react-icons/go";
import Loading from "../StyledComponents/Loading";

const SignUp = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------//

  const { state } = useLocation();
  const dispatch = useDispatch();

  const [PasswordShow, setPasswordShow] = useState(true);
  const verified = useSelector((state) => state.Signup.verified);
  const showSuccess = useSelector((state) => state.Signup.showSuccess);
  const saveLoading = useSelector((state) => state.Signup.saveLoading);
  const [errMsg, setErrMsg] = useState(false);
  const [email] = useState(state ? state.type.email : "");
  const [countryCodes, setCountryCodes] = useState([]);
  const [error, setError] = useState({
    firstErr: false,
    lastErr: false,
    mailErr: false,
    clinicErr: false,
    usernameErr: false,
    mobileErr: false,
    passwordErr: "",
    nameLengthErr: false,
    clinicNameError: false,
    countryErr: false,
  });
  const [userData, setUserData] = useState({
    first: "",
    last: "",
    mail: "",
    clinic: "",
    username: "",
    password: "",
    mobile: "",
  });
  const [def, setdef] = useState(false);
  const [lenErr, setLenErr] = useState(false);
  const minimum = 9;
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState("");
  const [codeCountry, setCodeCountry] = useState("");
  const [codeOnly, setCodeOnly] = useState("");
  // var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  let actionUrl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    actionUrl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    actionUrl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  const rpm_image =
    process.env.REACT_APP_BACKEND_FILE_ACCESS +
    "/sites/default/images/rpm_logo.png";

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const mouseClick = () => {
    setShowOptions(!showOptions);
  };

  const changeCountryCode = (data) => {
    let values = data.split("-");
    setSearch(values[0] + " ( +" + values[1] + " )");
    setCodeCountry("+" + values[1]);
    setCodeOnly(values[2]);
    setError((prevError) => ({ ...prevError, countryErr: false }));
  };

  const handleSearchChange = (data) => {
    setSearch(data);
    setCodeCountry("");
    setCodeOnly("");
  };

  const handleInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => ({
      ...prev,
      [`${name}Err`]: false,
    }));

    const onlyAlphabets = /^[a-zA-Z ]*$/;
    const noSpecial = /^[a-zA-Z0-9 ]*$/;

    switch (name) {
      case "first":
        if (onlyAlphabets.test(e.target.value)) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            first: value,
          }));
        }
        break;
      case "last":
        if (onlyAlphabets.test(e.target.value)) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            last: value,
          }));
        }
        break;
      case "mail":
        setUserData((prevUserData) => ({
          ...prevUserData,
          mail: email,
        }));
        break;
      case "password":
        setLenErr(false);
        setUserData((prevUserData) => ({
          ...prevUserData,
          password: value,
        }));

        break;
      case "mobile":
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: value.replace(/[^0-9]/g, ""),
        }));
        break;
      case "clinic":
        if (onlyAlphabets.test(e.target.value)) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
          }));
        }
        setError((prevError) => ({ ...prevError, clinicNameError: false }));

        break;
      case "username":
        if (noSpecial.test(e.target.value)) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
          }));
          setError((prevError) => ({ ...prevError, nameLengthErr: false }));
        }

        break;
      default:
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: value,
        }));

        break;
    }
  };

  const handleToggle = (e) => {
    if (e.target.checked === true) {
      setdef((Prevsate) => !Prevsate);
    } else {
      setdef((Prevsate) => !Prevsate);
    }
  };

  const handleSubmit = () => {
    var err = 0;

    if (
      userData.password !== "" &&
      userData.password !== null &&
      userData.password !== undefined
    ) {
      var upper = 0,
        lower = 0,
        number = 0,
        special = 0;
      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      for (var i = 0; i < userData.password.length; i++) {
        if (!isNaN(userData.password[i] * 1)) number++;
        else if (format.test(userData.password[i])) special++;
        else if (userData.password[i] === userData.password[i].toLowerCase())
          lower++;
        else if (userData.password[i] === userData.password[i].toUpperCase())
          upper++;
      }
    }

    if (userData.first === "") {
      setError((prevError) => ({ ...prevError, firstErr: true }));
      err = 1;
    }
    if (userData.last === "") {
      setError((prevError) => ({ ...prevError, lastErr: true }));
      err = 1;
    }
    if (userData.mobile === "") {
      setError((prevError) => ({
        ...prevError,
        mobileErr: true,
      }));
      err = 1;
    }
    if (codeCountry === "") {
      setError((prevError) => ({
        ...prevError,
        countryErr: true,
      }));
      err = 1;
    }
    if (userData.mail === "") {
      setError((prevError) => ({ ...prevError, mailErr: true }));
      err = 1;
    }
    if (userData.username === "") {
      setError((prevError) => ({ ...prevError, usernameErr: true }));
      err = 1;
    } else if (userData.username.length < 3) {
      setError((prevError) => ({ ...prevError, nameLengthErr: true }));
      err = 1;
    }
    if (userData.password === "") {
      setError((prevError) => ({ ...prevError, passwordErr: true }));
      setErrMsg("Enter a valid password");
      err = 1;
    }
    if (userData.clinic === "") {
      setError((prevError) => ({ ...prevError, clinicErr: true }));
      err = 1;
    }

    if (
      ["trial", "basic", "standard", "premium", "demo", "starter"].includes(
        userData.clinic.toLowerCase().trimStart().trimEnd()
      )
    ) {
      setError((prevError) => ({ ...prevError, clinicNameError: true }));
      err = 1;
    }

    if (upper === 0 || lower === 0 || number === 0 || special === 0) {
      setError((pre) => ({
        ...pre,
        passwordErr: true,
      }));
      setErrMsg("Your password is not strong enough");
      err = 1;
    } else {
      if (userData.password !== undefined) {
        if (userData.password.length < minimum) {
          setLenErr(true);
          err = 1;
        }
      }
    }

    if (!def) {
      setShowAlert(true);
      setErrorMsg("Please agree to our terms of service and privacy policy");
      setTimeout(() => {
        setShowAlert(false);
        setErrorMsg("");
      }, 1500);
      err = 1;
    }

    if (err > 0) {
      return false;
    }
    dispatch(setSaveLoading(true));
    let finalData = {
      ...userData,
      mobile: "(" + codeCountry + ")" + userData.mobile,
    };

    axios
      .post(actionUrl + "/interface/register/createsite.php", {
        ...finalData,
        action: "insert",
      })
      .then((res) => {
        if (res.data.msg === "success") {
          let dbName = res.data.db_name;
          axios
            .post(actionUrl + "/interface/register/action.php", {
              ...finalData,
              action: "insert",
            })
            .then((result) => {
              if (result.data.msg === "success") {
                dispatch(setSaveLoading(false));
                dispatch(userSignupEnd(true));
                dispatch(
                  verifyUser({
                    email,
                    otp: "",
                    type: "account",
                    database: dbName,
                    facility: result.data.facility
                      .replace(/[^\w\s]/gi, "")
                      .replace(/\s+/g, "")
                      .toLowerCase(),
                    mode: "verify",
                  })
                );
              }
            })
            .catch((err) => {
              dispatch(setSaveLoading(false));
              console.log(err.error);
            });
          setErrorMsg("");
        } else if (res.data.msg === "sitename already taken") {
          setError((prevError) => ({ ...prevError, clinicErr: true }));
          dispatch(setSaveLoading(false));
          setShowAlert(true);
          setErrorMsg(res.data.msg);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          dispatch(setSaveLoading(false));
          setShowAlert(true);
          setErrorMsg("Failed to add account");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => {
        dispatch(setSaveLoading(false));
        console.log("failed");
      });
  };

  // ------------------------------ Functions End -------------------------------------------------------------//

  // ------------------------------ useEffect Start -------------------------------------------------------------//

  useEffect(() => {
    if (!verified) {
      window.location.href = "/";
    }
    if (email !== "") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        mail: email,
      }));
    }

    axios
      .get(actionUrl + "/interface/register/get_country_code_rpm.php")
      .then((res) => {
        setCountryCodes(res.data);
      })
      .catch((err) => {
        console.error(err);
        setCountryCodes([]);
      });
  }, []);

  useEffect(() => {
    if (codeCountry !== "" && userData.mobile !== "") {
      axios
        .post(actionUrl + "/interface/register/validatephonenumber.php", {
          phone_number: userData.mobile,
          country_code: codeOnly,
        })
        .then((res) => {
          if (res.data === "invalid") {
            setError((prevError) => ({
              ...prevError,
              mobileErr: true,
            }));
          } else {
            setError((prevError) => ({
              ...prevError,
              mobileErr: false,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
          setError((prevError) => ({
            ...prevError,
            mobileErr: false,
          }));
        });
    }
  }, [userData.mobile, codeCountry]);

  // ------------------------------ useEffect End -------------------------------------------------------------//

  return (
    <Div
      height="100%"
      padding="20px"
      width="100%"
      backgroundColor="#F0F4F7"
      flexCenterAll
    >
      {saveLoading ? (
        <Loading />
      ) : (
        <Div width="40%">
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            textAlign="center"
            display="block"
            margin="auto"
          />
          <Div backgroundColor="#FFFFFF" borderRadius="16px" padding="40px">
            <PageTitle
              color="#2D3748"
              fontSize="24px"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              marginBottom="16px"
              textAlign="center"
            >
              Get Started
            </PageTitle>

            {/* First name */}
            <RowDiv width="50%" inline flexDirection="column">
              <FormLabel
                marginBottom="4px"
                color="#7D8592"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="24px"
                fontWeight="500"
              >
                First Name<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Input
                height="44px"
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                border="1px solid #D8E0F0"
                btlr="4px"
                bblr="4px"
                paddingLeft="12px"
                placeholder="Enter name"
                name="first"
                onChange={handleInput}
                value={userData.first}
                style={
                  error.firstErr
                    ? {
                        border: "1px solid red",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }
                    : {}
                }
              />
              {error.firstErr && (
                <ErrorMessage>Please enter a valid first name</ErrorMessage>
              )}
            </RowDiv>

            {/* Last name */}

            <RowDiv width="50%" inline flexDirection="column">
              <FormLabel
                color="#7D8592"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                marginLeft="12px"
                lineHeight="24px"
                marginBottom="4px"
              >
                Last Name<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Input
                height="44px"
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                border="1px solid #D8E0F0"
                btrr="4px"
                name="last"
                bbrr="4px"
                paddingLeft="12px"
                placeholder="Enter name"
                borderLeft="none"
                onChange={handleInput}
                value={userData.last}
                style={
                  error.lastErr
                    ? {
                        border: "1px solid red",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        borderLeft: "none",
                      }
                    : {}
                }
              />
              {error.lastErr && (
                <ErrorMessage>Please enter a valid last name</ErrorMessage>
              )}
            </RowDiv>

            {/* Email */}

            <RowDiv
              width="100%"
              flexDirection="column"
              className="sign-up-mail-row"
            >
              <FormLabel
                color="#7D8592"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="24px"
                marginBottom="4px"
              >
                Email Address<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Input
                height="44px"
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                border="1px solid #D8E0F0"
                borderRadius="4px"
                name="mail"
                paddingLeft="40px"
                placeholder="Enter your email"
                onChange={handleInput}
                value={userData.mail}
                disabled
                style={
                  error.mailErr
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
              />
              <GoMail />
              {error.mailErr && (
                <ErrorMessage>Please enter a valid mail address</ErrorMessage>
              )}
            </RowDiv>

            {/* Clinic/Hospital */}

            <RowDiv
              width="100%"
              flexDirection="column"
              className="sign-up-mail-row"
            >
              <FormLabel
                color="#7D8592"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="24px"
                marginBottom="4px"
              >
                Clinic/Hospital name<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Input
                height="44px"
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                border="1px solid #D8E0F0"
                borderRadius="4px"
                name="clinic"
                paddingLeft="40px"
                placeholder="Enter your clinic name"
                onChange={handleInput}
                value={userData.clinic}
                style={
                  error.clinicErr || error.clinicNameError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
              />
              <GoMail />
              {error.clinicErr && (
                <ErrorMessage>
                  {errorMsg !== ""
                    ? errorMsg
                    : "Please enter a valid clinic name"}
                </ErrorMessage>
              )}
              {error.clinicNameError && (
                <ErrorMessage>
                  {errorMsg !== ""
                    ? errorMsg
                    : "This name can't be used. Enter another name"}
                </ErrorMessage>
              )}
            </RowDiv>
            {/* username */}

            <RowDiv width="100%" flexDirection="column">
              <FormLabel
                color="#7D8592"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="24px"
                marginBottom="4px"
              >
                User Name<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Input
                height="44px"
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                border="1px solid #D8E0F0"
                name="username"
                borderRadius="4px"
                paddingLeft="12px"
                placeholder="Enter your user name"
                onChange={handleInput}
                value={userData.username}
                style={
                  error.usernameErr || error.nameLengthErr
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
              />
              {error.usernameErr && (
                <ErrorMessage>Please enter a valid username</ErrorMessage>
              )}
              {error.nameLengthErr && (
                <ErrorMessage>
                  Username should contain atleast 3 characters
                </ErrorMessage>
              )}
            </RowDiv>
            {/* Password */}

            <RowDiv width="100%" flexDirection="column">
              <FormGroup display="grid" width="100%" position="relative">
                <FormLabel
                  marginBottom="4px"
                  color="#7D8592"
                  lineHeight="24px"
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="500"
                >
                  Password<SpanTag color="#B00020">*</SpanTag>
                </FormLabel>
                <Input
                  placeholder="Enter password"
                  autoComplete="off"
                  type={PasswordShow ? "password" : "text"}
                  height="44px"
                  fontFamily="'Open Sans', sans-serif"
                  color="#718096"
                  name="password"
                  borderRadius="4px"
                  border="1px solid #D8E0F0"
                  paddingLeft="12px"
                  marginBottom={error.passwordErr || lenErr ? "0px" : "8px"}
                  onChange={handleInput}
                  value={userData.password}
                  style={
                    error.passwordErr || lenErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                />
                {PasswordShow ? (
                  <FaRegEye
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "40px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                )}
                {error.passwordErr && (
                  <ErrorMessage marginBottom="8px">{errMsg}</ErrorMessage>
                )}
                {lenErr && (
                  <ErrorMessage marginBottom="8px">
                    {`Password should contain atleast ${minimum} character`}
                  </ErrorMessage>
                )}
                <Span
                  fontFamily="'Open Sans', sans-serif"
                  color="#718096"
                  fontSize="14px"
                  lineHeight="19px"
                >
                  <SpanTag bullet marginRight="8px" />
                  {`Password should have minimum ${minimum} characters, Should contain atleast
              one uppercase, lowercase, number and special character`}
                </Span>
              </FormGroup>
            </RowDiv>

            {/* Mobile */}

            <RowDiv width="100%" flexDirection="column">
              <FormLabel
                color="#7D8592"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                marginBottom="4px"
              >
                Mobile<SpanTag color="#B00020">*</SpanTag>
              </FormLabel>
              <Div display="flex">
                {/* Autocomplete Start */}

                <Div className="for_autoComplete_bi_Patient pat-search-autocomplete top-nv-pat-srch">
                  {/* <AiOutlineSearch /> */}
                  <AutoComplete
                    onClick={mouseClick}
                    // onMouseLeave={handleMouseLeave}
                    placeholder="Country code"
                    onSelect={changeCountryCode}
                    onSearch={handleSearchChange}
                    popupClassName={search !== "" ? "" : "for_no_loading"}
                    notFoundContent="No results found"
                    className="top-nav-pat-search"
                    id="top-nav-pat-search"
                    options={countryCodes
                      .filter((item) => {
                        let s = String(item.full_name);
                        let number = String(item.number);
                        return (
                          s
                            .toLowerCase()
                            .includes(search.toLowerCase().trimEnd()) ||
                          number.includes(
                            search.replace(/[+\s]/g, "").trimEnd()
                          )
                        );
                      })
                      .map((x, index) => {
                        return {
                          label: x.full_name + " ( +" + x.number + " )",
                          value: x.full_name + "-" + x.number + "-" + x.country,
                          key: x.number + "_" + index,
                        };
                      })}
                    value={search}
                    style={
                      error.countryErr
                        ? {
                            border: "1px solid red",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            borderRight: "none",
                          }
                        : {}
                    }
                  />
                </Div>

                {/* Autocomplete End */}
                <Input
                  height="44px"
                  fontFamily="'Open Sans', sans-serif"
                  color="#718096"
                  border="1px solid #D8E0F0"
                  paddingLeft="12px"
                  width="60%"
                  placeholder="Enter your Number"
                  name="mobile"
                  btrr="4px"
                  bbrr="4px"
                  marginBottom={
                    error.mobileErr || error.countryErr ? "0px" : "4px"
                  }
                  onChange={handleInput}
                  value={userData.mobile}
                  style={
                    error.mobileErr || error.countryErr
                      ? { border: "1px solid red", borderTopRightRadius: "5px" }
                      : {}
                  }
                />
              </Div>
              {(error.mobileErr || error.countryErr) && (
                <ErrorMessage marginBottom="4px">
                  {`Please enter a valid mobile number ${
                    error.countryErr && " and a valid country code"
                  }`}
                </ErrorMessage>
              )}

              <Span
                fontFamily="'Open Sans', sans-serif"
                color="#718096"
                fontSize="12px"
              >
                For text verification to access your account.
              </Span>
            </RowDiv>
            {/* check box */}
            <RowDiv
              width="100%"
              flexDirection="column"
              marginBottom="40px"
              mt="24px"
            >
              <Div display="flex">
                <CheckBoxWrapper>
                  <TogggleCheck
                    checked={def}
                    onChange={() => {}}
                    cursor_2={false}
                    type="checkbox"
                    onClick={(e) => handleToggle(e)}
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </CheckBoxWrapper>
                <FormLabel
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="19px"
                  color="#2E2E2E"
                  marginLeft="8px"
                  htmlFor="agree"
                >
                  On behalf of myself and the practice, I agree to the Business
                  Associate Agreement, the
                  <SpanTag color="#5B8DDD" fontFamily="'Inter', sans-serif">
                    &nbsp;Terms of Service,
                  </SpanTag>
                  and&nbsp;
                  <SpanTag color="#5B8DDD" fontFamily="'Inter', sans-serif">
                    the Privacy Policy
                  </SpanTag>
                  &nbsp;for my account.
                </FormLabel>
              </Div>
            </RowDiv>

            {/* Save Button */}

            <SignupButton onClick={handleSubmit}>
              Sign Up
              <GoArrowRight />
            </SignupButton>
          </Div>
        </Div>
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={"fail"} msg={errorMsg} />
      )}

      <Success show={showSuccess} />
    </Div>
  );
};

export default SignUp;
