import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  height: 100%;
}

#rpm_signup {
  width: 100%;
  height: max-content;
  background:#FFF;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
}

nav a.active ,nav a.active #appointment,nav a.active #billing,nav a.active #document,nav a.active #healthprofile{
   
    color : #557FF5 !important;     
  }

  nav a{
    color: rgba(46, 46, 46, 0.5);
    &:hover{
      color : #557FF5 !important; 
    }
  }
`;

export default GlobalStyle;
