import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  setEmailRes,
  setOtp,
  setSaveLoading,
  endVeriyUser,
  setUserOtp,
} from "../../StateManagement/Reducers/SignupState";
import { LoginEmailCheck_Api } from "../Apis/SignupApi";

export function* LoginEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(LoginEmailCheck_Api, email);
    if (res.status === 200) {
      yield put(setSaveLoading(false));
      const Data = Decrypt_Value(res.data, "vozorpm");
      if (Data.output !== "email verified") {
        yield put(setOtp(Data.output));
        localStorage.setItem("LoginOTP", Encrypt_Value(Data.output, "vozorpm"));
      }
      yield put(setEmailRes(Data));
    } else {
      yield put(setSaveLoading(false));
    }
  } catch (e) {
    yield put(setSaveLoading(false));
    console.log(e.message);
  }
}

export function* VerifyUserWorker({ payload }) {
  const data = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(LoginEmailCheck_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(endVeriyUser(Data));
    }
  } catch (e) {
    yield put(endVeriyUser("failed"));
    console.log(e.message);
  }
}

export function* getOtpWorker({ payload }) {
  const data = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(LoginEmailCheck_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setUserOtp(Data));
    }
  } catch (e) {
    yield put(setUserOtp(null));
    console.log(e.message);
  }
}
