import { all } from "redux-saga/effects";

import {
  LoginEmailCheckSaga,
  VerifyUserSaga,
  GetOtpSaga,
} from "./Signup/SignupSagaWatchers";

export default function* rootSaga() {
  return yield all([LoginEmailCheckSaga(), VerifyUserSaga(), GetOtpSaga()]);
}
