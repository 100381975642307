import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import {
  Div,
  Span,
  PageTitle,
  Input,
  FormLabel,
  SpanTag,
  Image,
} from "../StyledComponents";
import {
  FormGroupInline,
  FormGroup,
  SaveButton,
  MainDiv,
} from "../EnterEmail/styles";
import {
  GetEmailRes,
  setEmailRes,
  verifyUser,
  setSaveLoading,
  getUserOtp,
} from "../../StateManagement/Reducers/SignupState";
import red_alert from "../../Assets/images/alert_abnormal.png";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Loading from "../StyledComponents/Loading";

const VerifyAccount = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [btndis, setbtndis] = useState(false);
  const [email] = useState(state ? state.type.email : "");
  const [Otp, setOtp] = useState(new Array(4).fill(""));
  const [otpErr, setOtpErr] = useState(false);
  const [otpErr1, setOtpErr1] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const emailResponse = useSelector((state) => state.Signup.EmailResp);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const saveLoading = useSelector((state) => state.Signup.saveLoading);
  const rpm_image =
    process.env.REACT_APP_BACKEND_FILE_ACCESS +
    "/sites/default/images/rpm_logo.png";

  const LoginOTP =
    localStorage.getItem("LoginOTP") &&
    Decrypt_Value(localStorage.getItem("LoginOTP"), "vozorpm");

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };
  const currentTime = new Date();
  const otpTime = emailResponse.otpValidTime;
  const otpDate = new Date(otpTime);
  const handleVerify = (e) => {
    if (Otp.length === 4 && Otp.every((element) => element === "")) {
      setOtpErr1(true);
      setOtpErr(false);
      return;
    }

    if (currentTime > otpDate) {
      setOtpErr(true);
      setOtpErr1(false);
      return;
    }

    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();
    if (parseInt(LoginOTP) === parseInt(Otp.join(""))) {
      setOtpErr(false);
      dispatch(
        verifyUser({ email, otp: LoginOTP, type: "verified", mode: "verify" })
      );
      Navigate("/signup", { state: { type: { email } } });
      localStorage.removeItem("LoginOTP");
    } else {
      setOtpErr(true);
      setOtpErr1(false);
      // localStorage.removeItem("OTP");
    }
  };

  const handleOtp = (e, i) => {
    setOtpErr(false);
    setOtpErr1(false);
    if (isNaN(e.value)) return;
    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };
  const ResendOtp = () => {
    dispatch(setSaveLoading(true));
    setOtp(new Array(4).fill(""));
    setOtpErr(false);
    dispatch(GetEmailRes({ email, mode: "check_email" }));
  };

  useEffect(() => {
    dispatch(
      setEmailRes({
        status: "",
        output: "",
        otpValidTime: emailResponse.otpValidTime,
      })
    );
    dispatch(getUserOtp({ email, mode: "get_otp" }));
  }, []);

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setShowAlert(true);
      setModalMsg("Mail sent successfully");
      setStatus("success");

      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");

        Navigate("/verify_account", {
          state: { type: { email } },
        });

        dispatch(
          setEmailRes({
            status: "",
            output: "",
            otpValidTime: emailResponse.otpValidTime,
          })
        );
      }, 1500);
    } else if (emailResponse.status === "Failure") {
      setShowAlert(true);
      setModalMsg("Mail sent failed");
      setStatus("fail");
      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");
        dispatch(setEmailRes({}));
      }, 1500);
    }
  }, [emailResponse]);

  return (
    <Div height="100vh" width="100%" backgroundColor="#F0F4F7" flexCenterAll>
      {saveLoading ? (
        <Loading />
      ) : (
        <MainDiv>
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            textAlign="center"
            display="block"
            margin="auto"
          />
          <Div backgroundColor="#FFFFFF" borderRadius="16px" padding="40px">
            <PageTitle
              fontFamily="'Open Sans', sans-serif"
              textAlign="center"
              fontWeight="800"
              marginBottom="8px"
            >
              Verify your Account
            </PageTitle>
            <Span
              color="#667085"
              fontFamily="'Open Sans', sans-serif"
              fontSize="20px"
              textAlign="center"
              marginBottom="31px"
            >
              Check you email, we just sent you a verification link to{" "}
              <Span color="#407BFF" fontWeight="600">
                {email}
              </Span>
            </Span>

            <FormGroupInline>
              {Otp.map((row, i) => {
                return (
                  <FormGroup key={i}>
                    <Input
                      key={i}
                      fontSize="36px"
                      lineHeight="44px"
                      height="100%"
                      fontWeight="700"
                      fontFamily="'Urbanist'"
                      width="100%"
                      border="1px solid #D8E0F0"
                      borderRadius="8px"
                      maxLength="1"
                      textAlign="center"
                      maxlength="1"
                      value={row}
                      onChange={(e) => handleOtp(e.target, i)}
                      onFocus={(e) => e.target.select()}
                      onKeyUp={(e) => KeyDown(e, i)}
                      onPaste={(e) => FieldPaste(e, i)}
                    />
                  </FormGroup>
                );
              })}
            </FormGroupInline>
            {otpErr1 && (
              <FormGroup width="100%" display="inline-grid" gridColumn="1/5">
                <Div display="flex" justifyContent="center">
                  <Image src={red_alert} width="24px" height="24px"></Image>
                  <FormLabel
                    marginLeft="6px"
                    textAlign="center"
                    fontSize="15px"
                    color="#B00020"
                    fontWeight="400"
                    lineHeight="24px"
                    fontFamily="inter"
                  >
                    Please enter verification code.
                  </FormLabel>
                </Div>
              </FormGroup>
            )}
            {otpErr && (
              <FormGroup width="100%" textAlign="center">
                <FormLabel
                  padding="0px 20%"
                  marginBottom="6px"
                  textAlign="center"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  fontFamily="inter"
                >
                  OTP is invalid
                </FormLabel>
              </FormGroup>
            )}
            <SaveButton
              backColor="#407BFF"
              disabled={btndis}
              onClick={(e) => handleVerify(e)}
            >
              Verify Account
            </SaveButton>
            <Span textAlign="center" marginTop="24px">
              Don't receive verification code&#63;&nbsp;
              <SpanTag
                color="#407BFF"
                cursor="pointer"
                onClick={() => ResendOtp()}
              >
                Resend Code
              </SpanTag>
            </Span>
          </Div>
        </MainDiv>
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </Div>
  );
};

export default VerifyAccount;
