import styled from "styled-components";
import { Div, Button } from "../StyledComponents";

export const FormGroupInline = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  width: 85%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  // @media (max-width: 820px) {
  //   width: 90% !important;
  //   margin-left: auto !important;
  // }
  // @media (max-width: 1650px) {
  //   width: 60%;
  // }
  // @media (max-width: 1440px) {
  //   width: 75%;
  // }
  @media (max-width: 1200px) {
    width: 90%;
  }
  // @media (max-width: 1024px) {
  //   width: 90%;
  //   margin-left: 0px;
  // }
`;

export const RowDiv = styled(Div)`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  margin-top: ${({ mt }) => (mt ? mt : "16px")};

  &.sign-up-mail-row {
    position: relative;
  }
  &.sign-up-mail-row svg {
    position: absolute;
    top: 40px;
    left: 12px;
    color: #718096;
    width: 20px;
    height: 20px;
  }
`;

export const SignupButton = styled(Button)`
  display: flex;
  color: white;
  width: 100%;
  border-radius: 8px;
  padding: 8px 24px;
  font-weight: 700;
  font-size: 16px;
  border: none;
  height: 48px;
  background-color: #3f8cff;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #005fb2;
    border-color: #005fb2;
  }

  &:active {
    background-color: rgba(44, 123, 229, 0.75);
  }

  & svg {
    font-size: 24px;
    padding-left: 8px;
  }
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 16px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const TogggleCheck = styled.input`
  cursor: ${(props) => (props.cursor_2 ? "not-allowed" : "pointer")};
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 26px;
  height: 16px;
  position: relative;
  left: 4px;
  &:checked + ${CheckBoxLabel} {
    background: #2c7be5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 14px;
      transition: 0.2s margin-left;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const FormGroup = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: relative;
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
  grid-column: ${(p) => p.gridColumn && p.gridColumn};
`;

export const SaveButton = styled(Button)`
  margin-top: 24px;
  background-color: ${(p) => p.backColor && p.backColor};
  color: white;
  width: 100%;
  border-radius: 8px;
  padding: 8px 24px;
  border: none;

  font-family: "Open Sans", sans-serif;
  height: 48px;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #005fb2;
    border-color: #005fb2;
  }
`;

export const MainDiv = styled(Div)`
  width: 33%;
  @media (max-width: 1300px) {
    width: 40%;
  }
  @media (max-width: 1050px) {
    width: 50%;
  }
`;
