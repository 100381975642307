import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeVerification } from "../../StateManagement/Reducers/SignupState";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  ModalContainer,
  ModalBody,
  Div,
  Span,
  Button,
} from "../StyledComponents";
import { FaCheck } from "react-icons/fa";

const Success = ({ show }) => {
  const dispatch = useDispatch();
  const facilityName = useSelector((state) => state.Signup.facilityName);
  const Navigate = useNavigate();

  const goToLogin = () => {
    dispatch(changeVerification(false));
    localStorage.clear();

    window.location.href = "https://" + facilityName + ".rpm.vozohealth.com";
  };

  return (
    <Modal show={show} width="100%" height="100%" justifyContent="center">
      <ModalContainer
        justifyContent="center"
        borderRadius="8px"
        padding="36px 50px"
      >
        <ModalBody>
          <Div
            height="48px"
            width="48px"
            background="#2C7BE5"
            borderRadius="50%"
            color="white"
            flexCenterAll
            margin="auto"
          >
            <FaCheck />
          </Div>
          <Span
            fontFamily="'Open Sans', sans-serif"
            color="#2E2E2E"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24.51px"
            margin="auto"
            marginTop="16px"
            textAlign="center"
          >
            Success
          </Span>
          <Span
            fontFamily="'Open Sans', sans-serif"
            color="#2E2E2E"
            fontSize="14px"
            lineHeight="19.07px"
            marginTop="24px"
            textAlign="center"
          >
            Your sign in has been completed.
          </Span>
          <Span
            fontFamily="'Open Sans', sans-serif"
            color="#2E2E2E"
            fontSize="14px"
            lineHeight="19.07px"
            marginTop="8px"
            textAlign="center"
          >
            Login to check details
          </Span>
          <Button
            backgroundColor="#2C7BE5"
            height="36px"
            fontFamily="'Open Sans', sans-serif"
            cursor="pointer"
            padding="8px"
            marginTop="32px"
            onClick={goToLogin}
            border="none"
            color="white"
            borderRadius="4px"
            width="100%"
          >
            Login
          </Button>
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

export default Success;
