import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  Div,
  Span,
  PageTitle,
  Input,
  FormLabel,
  Image,
} from "../StyledComponents";
import { useSelector, useDispatch } from "react-redux";
import { MainDiv, SaveButton } from "./styles";
import {
  GetEmailRes,
  setEmailRes,
  setSaveLoading,
  changeVerification,
} from "../../StateManagement/Reducers/SignupState";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Loading from "../StyledComponents/Loading";

const EnterEmail = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const emailResponse = useSelector((state) => state.Signup.EmailResp);
  const saveLoading = useSelector((state) => state.Signup.saveLoading);
  const rpm_image =
    process.env.REACT_APP_BACKEND_FILE_ACCESS +
    "/sites/default/images/rpm_logo.png";

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);

    if (email !== "") {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(email) == true) {
        dispatch(setSaveLoading(true));
        dispatch(GetEmailRes({ email, mode: "check_email" }));
        setStatus("success");
        setModalMsg("Your details are being verified.");
      } else {
        setMailErr(true);
        setErrMsg("Invalid Email");
      }
    } else {
      setMailErr(true);
      setErrMsg("Please Enter the Email Address");
    }
  };

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setShowAlert(true);
      if (emailResponse.output === "email verified") {
        setModalMsg("This email is already verified");
        setStatus("success");
        dispatch(changeVerification(true));

        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");

          Navigate("/signup", {
            state: { type: { email } },
          });

          dispatch(
            setEmailRes({
              status: "",
              output: "",
              otpValidTime: emailResponse.otpValidTime,
            })
          );
        }, 1500);
      } else {
        setModalMsg("Mail sent successfully");
        setStatus("success");

        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");

          Navigate("/verify_account", {
            state: { type: { email } },
          });

          dispatch(
            setEmailRes({
              status: "",
              output: "",
              otpValidTime: emailResponse.otpValidTime,
            })
          );
        }, 1500);
      }
    } else if (emailResponse.status === "Failure") {
      if (emailResponse.output === "Account Already Found!") {
        setMailErr(true);
        setErrMsg("This Email already exists");
      } else if (emailResponse.output === "Account Already created") {
        setShowAlert(true);
        setModalMsg("Account already created");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
          dispatch(setEmailRes({}));
        }, 1500);
      } else {
        setShowAlert(true);
        setModalMsg("Mail sent failed");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
          dispatch(setEmailRes({}));
        }, 1500);
      }
    }
  }, [emailResponse]);

  return (
    <Div height="100vh" width="100%" backgroundColor="#F0F4F7" flexCenterAll>
      {saveLoading ? (
        <Loading />
      ) : (
        <MainDiv>
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            textAlign="center"
            display="block"
            margin="auto"
          />
          <Div backgroundColor="#FFFFFF" borderRadius="16px" padding="40px">
            <PageTitle
              fontFamily="'Open Sans', sans-serif"
              textAlign="center"
              fontWeight="800"
              marginBottom="24px"
              fontSize="32px"
            >
              Enter Email
            </PageTitle>
            <Span
              color="#667085"
              fontFamily="'Open Sans', sans-serif"
              fontSize="20px"
              textAlign="center"
              marginBottom="20px"
            >
              Use the email that you want to use in the practice to get
              verification code
            </Span>
            <FormLabel
              fontFamily="'Open Sans', sans-serif"
              color="#7D8592"
              fontSize="14px"
              lineHeight="24px"
            >
              Email address
            </FormLabel>
            <Input
              width="100%"
              boxShadow="#B8C8E039"
              border="1px solid #D8E0F0"
              height="48px"
              marginTop="8px"
              borderRadius="8px"
              placeholder="Enter Email"
              style={
                mailErr ? { border: "1px solid red", borderRadius: "4px" } : {}
              }
              paddingLeft="18px"
              paddingRight="10px"
              onChange={(e) => EmailCheck(e)}
            />
            {mailErr && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
            <SaveButton
              backColor="#3F8CFF"
              disabled={btndis}
              onClick={(e) => HandleSubmit(e)}
            >
              Continue
            </SaveButton>
          </Div>
        </MainDiv>
      )}

      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </Div>
  );
};

export default EnterEmail;
