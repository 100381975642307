import styled, { css, keyframes } from "styled-components";

export const Image = styled.img`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  color: ${(p) => p.color && p.color};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  cursor: ${(p) => p.cursor && p.cursor};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  &.dropdownimg {
    @media screen and (max-width: 950px) {
      right: 32px;
      top: 16px;
    }
    @media screen and (max-width: 750px) {
      right: 32px;
      top: 16px;
    }
  }
`;

export const Div = styled.div`
  visibility: ${({ visibility }) => visibility && visibility};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  box-sizing: ${(p) => p.boxSizing && p.boxSizing};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  gap: ${(p) => p.gap && p.gap};
  border-right: ${(p) => p.borderRight && p.borderRight};
  transform: ${(p) => p.transform && p.transform};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  float: ${(p) => p.float && p.float};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  border-top-left-radius: ${(p) =>
    p.borderTopLeftRadius && p.borderTopLeftRadius};
  border-top-right-radius: ${(p) =>
    p.borderTopRightRadius && p.borderTopRightRadius};
  border-bottom-left-radius: ${(p) =>
    p.borderBottomLeftRadius && p.borderBottomLeftRadius};
  border-bottom-right-radius: ${(p) =>
    p.borderBottomRightRadius && p.borderBottomRightRadius};

  max-width: ${(p) => p.maxWidth && p.maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  min-height: ${(p) => p.minHeight && p.minHeight};
  /* grid-area: ${(p) => p.gridArea && p.gridArea}; */
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width && p.width;
    }
  }};

  &.for_autoComplete_bi_Patient {
    display: flex;
    position: relative;
    width: 40%;
    height: 44px;
  }
  &.for_autoComplete_bi_Patient svg {
    z-index: 1;
    margin: 8px 0px 0px 6px;
    width: 18px;
    height: 18px;
  }
  &.for_autoComplete_bi_Patient .ant-select-auto-complete {
    // position: absolute;
    width: 100%;
  }

  &.for_autoComplete_bi_Patient input {
    border: none !important;
  }

  &.for_autoComplete_bi_Patient .ant-select-selection-placeholder {
    color: #000;
    padding-top: 1px;
  }

  &.for_autoComplete_bi_Patient .ant-select-single .ant-select-selector {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }
  &.for_autoComplete_bi_Patient .ant-select-single {
    height: 44px !important;
  }

  &.for_autoComplete_bi_Patient
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: none !important;
    box-shadow: none !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & svg {
    opacity: 0.7;
  }
`;

export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  &.flexWrapTxt {
    @media screen and (max-width: 1071px) {
      margin-left: 13px;
    }
  }
  &.flexWrapDt {
    @media screen and (max-width: 1071px) {
      margin-left: 23px;
    }
  }
  /* @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  } */
`;

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.flex && p.flex};
  align-items: ${(p) => p.alignItems && p.alignItems};
  color: ${(p) => p.color && p.color};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
`;

export const Button = styled.button`
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  /* background-color: ${(p) => p.backgroundColor && p.backgroundColor}; */
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  border-color: ${(p) => p.borderColor && p.borderColor};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
  outline: ${(p) => p.outline && p.outline};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};

  background-color: ${(p) => p.backgroundColor && p.backgroundColor};

  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }

  &:active {
    background-color: ${(p) =>
      p.activeBackgroundColor || p.hoverBackColor || "initial"};
  }

  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    position: ${(p) => p.svgPosition && p.svgPosition};
    top: ${(p) => p.svgTop && p.svgTop};
    left: ${(p) => p.svgLeft && p.svgLeft};
  }

  &:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ff4d4d;
  }

  ${(p) =>
    p.disabled &&
    css`
      /* pointer-events: none; */
      cursor: not-allowed;
      ${p.disableActive ? "" : `background-color: rgba(44, 123, 229, 0.5);`}
      /* Add hover and active styles for disabled state */
      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}
  &:hover {
    &.bgFFF {
      background-color: #f4f6f9;
    }
    &.bg-2c7be5 {
      background-color: #005fb2;
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      /* pointer-events: none; */
      cursor: not-allowed;
      background-color: rgba(44, 123, 229, 0.5);

      /* Add hover and active styles for disabled state */
      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}
`;

export const Span = styled.p`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-shadow: ${(p) => p.textShadow && p.textShadow};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  cursor: ${(p) => p.cursor && p.cursor};
  &.Customizerole-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
    }
  }

  &.roles-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
      postition: absolute;
    }
  }
`;

// Form //
export const Form = styled.form``;

export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
`;

export const FormGroup = styled.div`
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: ${(p) => p.position && p.position};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
  float: ${(p) => p.float && p.float};
`;
export const Label = styled.label`
  font-size: ${(p) => p.size && p.size};
  font-weight: ${(p) => p.weight && p.weight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.display && p.display};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;
export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  border-top-left-radius: ${(p) => p.btlr && p.btlr};
  border-top-right-radius: ${(p) => p.btrr && p.btrr};
  border-bottom-left-radius: ${(p) => p.bblr && p.bblr};
  border-bottom-right-radius: ${(p) => p.bbrr && p.bbrr};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:focus {
    outline: none;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  cursor: ${(p) => p.cursor && p.cursor};
  /* min-width: 135px; */
  &.hide-icon::-webkit-search-cancel-button,
  .hide-icon::-webkit-search-decoration,
  .hide-icon::-webkit-search-results-button,
  .hide-icon::-webkit-search-results-decoration {
    display: none;
  }
  &::placeholder {
    color: ${(p) => p.pColor && p.pColor};
  }
`;
// Form
// Check box
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: ${(p) => p.position && p.position};
  opacity: ${(p) => p.opacity && p.opacity};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const StyledCheckbox = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background-color: ${(p) => (p.checked ? p.backgroundColor : "transparent")};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;

// Table
export const Table = styled.table`
  margin-top: 1px;
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => (p.height ? p.height : "100px")};
  border: ${(p) => (p.border ? p.border : "1px solid #E2E8ED")};
  border-top: ${(p) => (p.borderTop ? p.borderTop : "none")};
  width: ${(p) => (p.width ? p.width : "100%")};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  border-collapse: ${(p) => p.collapse && p.collapse};
  border-top-left-radius: ${(p) =>
    p.borderTopLeftRadius && p.borderTopLeftRadius};
  border-top-right-radius: ${(p) =>
    p.borderTopRightRadius && p.borderTopRightRadius};
  border-bottom-left-radius: ${(p) =>
    p.borderBottomLeftRadius && p.borderBottomLeftRadius};
  border-bottom-right-radius: ${(p) =>
    p.borderBottomRightRadius && p.borderBottomRightRadius};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};

  &.getlast > tbody:last-of-type > tr > td {
    border-bottom: none !important;
  }
  @media (min-width: 1575px) and (max-width: 3500px) {
    &.treat_dashboard_ {
      height: 60px !important;
    }
  }
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  z-index: ${(p) => p.zIndex && p.zIndex};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
  min-width: ${(p) => p.minWidth && p.minWidth};
  overflow: ${(p) => p.overflow && p.overflow};
  width: ${(p) => p.width && p.width};
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: 14px;
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  color: ${(p) => (p.color ? p.color : "#777984")};
  /* padding: ${(p) => (p.padding ? p.padding : "20px 20px 20px 30px")}; */
  /* padding-left: 24px; */
  padding: ${(p) => p.padding && p.padding};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "20px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "20px")};
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  border-right: ${(p) => p.borderRight && p.borderRight};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  flex: ${(p) => p.flex && p.flex};
  &.activeColor {
    color: #2c7be5 !important;
  }
  cursor: ${(p) => p.cursor && p.cursor};
  @media (min-width: 800px) and (max-width: 820px) {
    width: ${(p) => p.resWidth && p.resWidth};
  }
`;

export const TableBodyCell = styled.td`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "15px")};
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")} !important;
  color: ${(p) => (p.color ? p.color : " #000000")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.borderBottom ? p.borderBottom : "none")};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  padding-right: ${(p) => (p.paddingRight ? p.paddingRight : "16px")};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "12px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "12px")};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  border-right: ${(p) => p.borderRight && p.borderRight};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  flex: ${(p) => p.flex && p.flex};
  width: ${(p) => p.width && p.width};
  width: ${(props) => props.columnWidth || "auto"};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border: ${(p) => p.border && p.border};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-wrap: ${(p) => p.wordWrap && p.wordWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  @media screen and (max-width: 820px) {
    &.maxWidthChange {
      max-width: 20px;
    }
  }
`;
export const TableRow = styled.tr`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  text-align: left;
  background-color: ${(props) => props.background};
  cursor: ${(p) => p.cursor && p.cursor};
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  border-bottom: ${(p) => p.border_Bottom && p.border_Bottom};
  height: ${(p) => (p.height ? p.height : "")};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
  }
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};

  &:hover {
    background-color: ${(p) => p.bghovercolor && p.bghovercolor};
  }
  border: ${({ border }) => border && border};
  text-align: ${({ textAlign }) => textAlign && textAlign};

  display: ${({ display }) => (display ? display : "table-row")};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => p.lineHeight && p.lineHeight};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
    border-right: ${(p) => p.borderRight && p.borderRight};
  }
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => (p.lastchild ? "none" : p.lastchild)};
    border-right: ${(p) => (p.lastchild ? "none" : p.lastchild)};
  }
  &.treat_dashboard_ tr:last-child {
    border-bottom: "none !important";
  }
`;

export const Modal = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  left: 0;
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1255)};
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;

export const ModalContainer = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  z-index: 1055;
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : " #ffffff"};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  cursor: auto;
  border: ${(p) => p.border && p.border};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  padding: ${(p) => p.padding && p.padding};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-bottom: ${(p) => (p.borderLess ? "none" : "1px solid #d8e0f0")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  height: ${(p) => p.height && p.height};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;

export const ModalTitle = styled.h4`
  line-height: ${(p) => (p.lineHight ? p.lineHight : "27px")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-style: ${(p) => (p.fontStyle ? p.fontStyle : p.fontStyle)};
  margin: ${(p) => p.margin && p.margin};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const ModalBody = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border-top: ${(p) => p.borderTop && p.borderTop};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border: ${(p) => p.border && p.border};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const SpanTag = styled.span`
  gap: ${(p) => p.gap && p.gap};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  position: ${(p) => p.position && p.position};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background-position: ${(p) => p.backgroundPosition && p.backgroundPosition};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  float: ${(p) => p.float && p.float};
  margin: ${(p) => p.margin && p.margin};
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};

  ${({ bullet }) =>
    bullet &&
    `
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;      
      background-color: #D8E0F0;
      height: 8px;
      width: 8px;
    `}
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
  margin-top: 4px;

  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Spinner = styled.div`
  position: relative;
  border: 4px solid #f3f3f3;
  border-left: 6px solid #3498db;
  border-bottom: 6px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spinAnimation} 1s linear infinite;
  font-weight: bold;
`;
