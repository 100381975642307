import axios from "axios";

let actionUrl = process.env.REACT_APP_BACKEND_FILE_ACCESS;

//change this api to check email from verification table
export const LoginEmailCheck_Api = async (data) =>
  await axios.get(actionUrl + "/interface/register/rpm_verification.php", {
    params: data,
  });

//no need
// export const CheckSignupDataApi = async (data) =>
//   await axiosInst.post(`/vozorpm/check/signup/data`, data);

// export const VerifyUserApi = async (data) =>
//   await axios.get(actionUrl + "/interface/register/rpm_verification.php", {
//     params: data,
//   });

// export const setOtpApi = async (data) =>
//   await axios.get(actionUrl + "/interface/register/rpm_verification.php", {
//     params: data,
//   });
